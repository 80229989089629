import "slick-carousel";
import "magnific-popup";
import "@fancyapps/fancybox";
import watchHeight from "./watchHeight";

const $win = $(window);
const $doc = $(document);
const $body = $(document.body);

// Your code goes here...
// jQuery.ready is no longer needed

watchHeight({
  selector: "#alert-bar-desktop",
  prop: "--alert-bar-desktop-height",
});

watchHeight({
  selector: "#alert-bar-mobile",
  prop: "--alert-bar-mobile-height",
});

homeSlider();

boxSlider();

sectionSlider();

fancyBoxGallery();

preventParentNavClick();

navTrigger();

fancyBoxFormOnMobile();

$win.on("scroll", function () {
  stickyHeader();
});

function homeSlider() {
  $(".slider .slider__slides").slick({
    dots: true,
    arrows: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
  });
}

function boxSlider() {
  if ($(".section-boxes").length && $win.width() < 768) {
    const sliderSettings = {
      dots: false,
      arrows: true,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3000,
      mobileFirst: true,
      rows: 0,
      slide: ".section__box:not(.section__box--events)",
      responsive: [
        {
          breakpoint: 767,
          settings: "unslick",
        },
      ],
    };
    const sliderBoxes = $(".section-boxes").slick(sliderSettings);

    $win.on("resize", function () {
      if ($win.width() < 768 && !sliderBoxes.hasClass("slick-initialized")) {
        sliderBoxes.slick(sliderSettings);
      }
    });
  }
}

function sectionSlider() {
  $(".section__slider .section__slides").slick({
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 1500,
    fade: true,
    cssEase: "linear",
  });
}

function fancyBoxGallery() {
  $(".gallery-item a").fancybox({
    transitionIn: "elastic",
    easingIn: "easeOutBack",
    transitionOut: "elastic",
    easingOut: "easeInBack",
    opacity: false,
    hideOnContentClick: false,
    titleShow: true,
    titlePosition: "over",
    titleFromAlt: true,
    showNavArrows: true,
    enableKeyboardNav: true,
    cyclic: false,
  });
}

function fancyBoxFormOnMobile() {
  $(".header__link--mail").on("click", function (e) {
    if ($win.width() < 1024) {
      $.fancybox.open({
        src: "#mail-popup",
        type: "inline",
      });
      e.preventDefault();
    }
  });
}

function stickyHeader() {
  $body.toggleClass(
    "header-sticky",
    $win.scrollTop() + $(".header").outerHeight() >=
      $(".wrapper__inner > .nav").offset().top
  );
}

function preventParentNavClick() {
  $(".nav .menu-item-has-children > a").one("click", function () {
    !isMobile();
  });
}

function isMobile() {
  return /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
}

function navTrigger() {
  $(".nav-trigger").on("click", function (e) {
    $("body").toggleClass("header-visible");
    e.preventDefault();
  });
}
